<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Galileo Computing VueJS Dashboard
  Author: Ian Kibet
  Author URL: http://ian.galileoc.co.ke
========================================================================================== -->

<template>
  <vs-sidebar
    v-model="isSidebarActiveLocal"
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>
        {{ Object.entries(this.data).length === 0 ? 'ADD NEW' : 'UPDATE' }}
        ITEM
      </h4>
      <feather-icon
        icon="XIcon"
        class="cursor-pointer"
        @click.stop="isSidebarActiveLocal = false"
      />
    </div>
    <vs-divider class="mb-0" />

    <VuePerfectScrollbar
      :settings="settings"
      :key="$vs.rtl"
      class="scroll-area--data-list-add-new"
    >
      <div class="p-6">
        <vs-input
          v-validate="'required'"
          v-model="product_code"
          label="Item Code"
          class="mt-5 w-full"
          name="item-code"
        />
        <span v-show="errors.has('item-code')" class="text-danger text-sm">{{
          errors.first('item-code')
        }}</span>

        <vs-input
          v-validate="'required'"
          v-model="product_name"
          label="Item Name"
          class="mt-5 w-full"
          name="item-name"
        />
        <span v-show="errors.has('item-name')" class="text-danger text-sm">{{
          errors.first('item-name')
        }}</span>

        <!--
        <br><small class="ml-1 mt-5 w-full">Choose Category</small>
        <v-select class="w-full" label="category" name="product-category" :closeOnSelect="false" v-model="product_category" :options="productsCategories" :dir="$vs.rtl ? 'rtl' : 'ltr'" /><br> -->

        <vs-input
          v-validate="{
            required: true,
            numeric: true,
            regex: /\d+(\.\d+)?$/,
          }"
          v-model="cost_price"
          type="number"
          icon-pack="feather"
          icon="icon-arrow-down"
          label="Cost Price"
          class="mt-5 w-full"
          name="cost-price"
        />
        <span v-show="errors.has('cost-price')" class="text-danger text-sm">{{
          errors.first('cost-price')
        }}</span>

        <vs-input
          v-validate="{
            required: true,
            min_value: this.cost_price,
            numeric: true,
            regex: /\d+(\.\d+)?$/,
          }"
          v-model="retail_price"
          type="number"
          icon-pack="feather"
          icon="icon-arrow-up"
          label="Retail Price"
          class="mt-5 w-full"
          name="retail-price"
        />
        <span v-show="errors.has('retail-price')" class="text-danger text-sm">{{
          errors.first('retail-price')
        }}</span>

        <vs-input
          v-validate="{
            required: true,
            min_value: this.cost_price,
            numeric: true,
            regex: /\d+(\.\d+)?$/,
          }"
          v-model="wholesale_price"
          type="number"
          icon-pack="feather"
          icon="icon-arrow-up"
          label="Wholesale Price"
          class="mt-5 w-full"
          name="wholesale-price"
        />
        <span
          v-show="errors.has('wholesale-price')"
          class="text-danger text-sm"
          >{{ errors.first('wholesale-price') }}</span
        >

        <vs-button class="mr-6 mt-5" @click="addAlternateCurrency"
          >Manage Alternate Currency</vs-button
        >

        <vs-input
          v-validate="{ required: false, numeric: true }"
          v-model="low_stock"
          type="number"
          icon-pack="feather"
          label="Low Stock Alarm"
          class="mt-5 w-full"
          name="low-stock"
        />
        <span class="text-sm">Set 0 to disable this feature.</span>
        <span v-show="errors.has('low-stock')" class="text-danger text-sm">
          {{ errors.first('low-stock') }}</span
        >

        <div v-if="Object.entries(this.data).length === 0">
          <div class="mt-5">
            <small v-show="!all_branches || branchOptions.length > 1"
              >Select Availability</small
            >
            <p v-if="branchOptions.length < 1" class="m-2">
              No branches available
            </p>
            <ul class="mt-2">
              <li v-if="branchOptions.length > 1" class="m-2">
                <vs-checkbox v-model="all_branches"
                  >Available in all
                  {{ branchOptions.length || 0 }} branches</vs-checkbox
                >
              </li>
              <li
                v-show="!all_branches"
                v-for="branch in branchOptions"
                :key="branch._id"
                class="m-2"
              >
                <vs-checkbox
                  :disabled="all_branches"
                  v-model="av_branch[branch._id]"
                  @change="onCheckbox"
                  >{{ branch.branch_name }}</vs-checkbox
                >
              </li>
            </ul>
          </div>

          <div
            v-if="av_branch[branch._id] || all_branches"
            v-for="branch in branchOptions"
            :key="branch._id"
          >
            <vs-input
              v-validate="{
                required: true,
                numeric: true,
                regex: /\d+(\.\d+)?/,
                min: 0,
              }"
              :label="`${branch.branch_name} Branch Initial Stock`"
              v-model="s_av_branch[branch._id]"
              :name="`${branch.branch_name}-stock`"
              icon-pack="feather"
              icon="icon-shopping-bag"
              class="mt-5 w-full"
            />
            <span
              v-show="errors.has(`${branch.branch_name}`)"
              class="text-danger text-sm"
              >{{ errors.first(`${branch.branch_name}`) }}</span
            >
          </div>
        </div>
      </div>
    </VuePerfectScrollbar>

    <vs-popup
      :active.sync="showAlternateCurencyPopup"
      class=""
      title="Manage alternative currency"
    >
      <p></p>

      <div class="vx-row mb-10">
        <div class="vx-col w-full mt-5">
          <label>Select applicable currencies</label>
          <v-select
            :close-on-select="false"
            v-model="selectedCurrencies"
            :options="this.$root.settingsData?.data?.alternativeCurrency"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            multiple
            label="currencyCode"
          />
        </div>

        <div class="mr-5 ml-5 w-full">
          <form @submit.prevent data-vv-scope="manage-currencies">
            <!-- <h5 class="mt-5">#{{ index + 1 }} {{ currency.currencyCode }}</h5> -->
            <div
              v-for="(price, currencyCode, index) in prices"
              :key="currencyCode"
            >
              <h5 class="mt-5">#{{ index + 1 }} {{ currencyCode }}</h5>
              <h6 class="mt-1">
                {{
                  Number(1).toLocaleString('en-KE', {
                    style: 'currency',
                    currency: currencyCode,
                  })
                }}
                =
                {{
                  Number(getCurrencyRate(currencyCode)).toLocaleString(
                    'en-KE',
                    {
                      style: 'currency',
                      currency: $root.businessData.data.currency,
                    },
                  )
                }}
              </h6>
              <vs-input
                v-validate="{
                  required: true,
                  regex: /\d+(\.\d+)?$/,
                  min_value: 1,
                }"
                v-model="prices[currencyCode].cost_price"
                type="number"
                icon-pack="feather"
                icon="icon-arrow-down"
                label="Cost Price"
                class="mt-5 w-full"
                :name="`${currencyCode}-cost-price`"
              />
              <small v-show="!errors.has(`${currencyCode}-cost-price`)">
                {{
                  (
                    prices[currencyCode].cost_price *
                    getCurrencyRate(currencyCode)
                  ).toLocaleString('en-KE', {
                    style: 'currency',
                    currency: $root.businessData.data.currency,
                  })
                }}</small
              >
              <span
                v-show="errors.has(`${currencyCode}-cost-price`)"
                class="text-danger text-sm"
                >{{ errors.first(`${currencyCode}-cost-price`) }}</span
              >

              <vs-input
                v-validate="{
                  required: true,
                  min_value: prices[currencyCode].cost_price,
                  regex: /\d+(\.\d+)?$/,
                }"
                v-model="prices[currencyCode].retail_price"
                type="number"
                icon-pack="feather"
                icon="icon-arrow-up"
                label="Retail Price"
                class="mt-5 w-full"
                :name="`${currencyCode}-retail-price`"
              />
              <small v-show="!errors.has(`${currencyCode}-retail-price`)">
                {{
                  (
                    prices[currencyCode].retail_price *
                    getCurrencyRate(currencyCode)
                  ).toLocaleString('en-KE', {
                    style: 'currency',
                    currency: $root.businessData.data.currency,
                  })
                }}</small
              >
              <span
                v-show="errors.has(`${currencyCode}-retail-price`)"
                class="text-danger text-sm"
                >{{ errors.first(`${currencyCode}-retail-price`) }}</span
              >

              <vs-input
                v-validate="{
                  required: true,
                  min_value: prices[currencyCode].cost_price,
                  regex: /\d+(\.\d+)?$/,
                }"
                v-model="prices[currencyCode].wholesale_price"
                type="number"
                icon-pack="feather"
                icon="icon-arrow-up"
                label="Wholesale Price"
                class="mt-5 w-full"
                :name="`${currencyCode}-wholesale-price`"
              />
              <small v-show="!errors.has(`${currencyCode}-wholesale-price`)">
                {{
                  (
                    prices[currencyCode].wholesale_price *
                    getCurrencyRate(currencyCode)
                  ).toLocaleString('en-KE', {
                    style: 'currency',
                    currency: $root.businessData.data.currency,
                  })
                }}</small
              >
              <span
                v-show="errors.has(`${currencyCode}-wholesale-price`)"
                class="text-danger text-sm"
                >{{ errors.first(`${currencyCode}-wholesale-price`) }}</span
              >
            </div>

            <vs-button class="mr-6 mt-5" @click.prevent="saveAlternateCurrency"
              >Save</vs-button
            >
          </form>
        </div>
      </div>
    </vs-popup>

    <div slot="footer" class="flex flex-wrap items-center p-6">
      <vs-button :disabled="!isFormValid" class="mr-6" @click="submitData"
        >Submit</vs-button
      >
      <vs-button
        type="border"
        color="danger"
        @click="isSidebarActiveLocal = false"
        >Cancel</vs-button
      >
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import vSelect from 'vue-select';

export default {
  components: {
    VuePerfectScrollbar,
    'v-select': vSelect,
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      id: null,
      rev: null,
      init: false,
      showAlternateCurencyPopup: false,
      selectedCurrencies: [],
      prices: {},
      product_code: '',
      original_product_code: '',
      product_name: '',
      cost_price: 0,
      low_stock: 0,
      retail_price: 0,
      wholesale_price: 0,
      av_branch: {},
      s_av_branch: {},
      all_branches: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
    };
  },
  computed: {
    branchOptions() {
      return this.branchData ? this.branchData : [];
    },
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.initValues();
          this.$emit('closeSidebar');
          this.$validator.reset();
        }
      },
    },
    isFormValid() {
      return (
        !this.errors.any() &&
        this.product_code &&
        this.product_name &&
        this.cost_price > 0 &&
        this.retail_price > 0 &&
        JSON.stringify(this.s_av_branch) != JSON.stringify({})
      );
    },
  },
  watch: {
    selectedCurrencies(newCurrencies) {
      if (newCurrencies.length == 0) {
        this.prices = {};
      }

      newCurrencies.forEach((currency) => {
        if (!this.prices[currency.currencyCode]) {
          this.$set(this.prices, currency.currencyCode, {
            cost_price: 0,
            retail_price: 0,
            wholesale_price: 0,
          });
        }
      });

      const pricesLocal = this.prices;

      for (const currencyCode in pricesLocal) {
        if (Object.hasOwnProperty.call(pricesLocal, currencyCode)) {
          const exists = newCurrencies.findIndex(
            (nc) => nc.currencyCode == currencyCode,
          );
          if (exists === -1) {
            delete pricesLocal[currencyCode];
          }
        }
      }

      this.prices = pricesLocal;
    },
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        let {
          _id,
          _rev,
          product_code,
          product_name,
          stock,
          cost_price,
          low_stock,
          retail_price,
          wholesale_price,
          prices,
          currencies,
        } = JSON.parse(JSON.stringify(this.data));
        this.init = true;
        this.id = _id;
        this.rev = _rev;
        this.product_code = product_code;
        this.original_product_code = product_code;
        this.product_name = product_name;
        this.cost_price = cost_price;
        this.low_stock = low_stock;
        this.retail_price = retail_price;
        this.wholesale_price = wholesale_price || retail_price;
        this.prices = prices || {};
        this.selectedCurrencies = currencies || [];

        // { "c0513cdb-51d3-4f25-9001-3cccefd489e0": "23" }
        this.s_av_branch = stock;

        if (this.s_av_branch) {
          for (let i in this.s_av_branch) {
            if (this.s_av_branch.hasOwnProperty(i)) {
              // { "c0513cdb-51d3-4f25-9001-3cccefd489e0": true }
              this.av_branch[i] = true;
            }
          }
          if (
            Object.keys(this.s_av_branch).length === this.branchOptions.length
          ) {
            this.all_branches = true;
          }
        } else {
          this.s_av_branch = {};
          for (let i in this.branchOptions) {
            this.s_av_branch[this.branchOptions[i]._id] = '0';
          }
        }
        this.initValues();
      }
    },
  },
  pouch: {
    branchData() {
      return {
        database: 'express',
        selector: { type: 'branch' },
        sort: [
          {
            name: 'asc',
          },
        ],
      };
    },
    productData() {
      return {
        database: 'express',
        selector: {
          type: 'product',
        },
        sort: [
          {
            name: 'asc',
          },
        ],
      };
    },
  },
  created() {
    // Add barcode scan listener and pass the callback function
    this.$barcodeScanner.init(this.onBarcodeScanned);
  },
  destroyed() {
    // Remove listener when component is destroyed
    this.$barcodeScanner.destroy();
  },
  methods: {
    getCurrencyRate(currencyCode) {
      const currency = this.$root.settingsData?.data?.alternativeCurrency.find(
        (c) => c.currencyCode === currencyCode,
      );
      if (!currency) {
        this.$vs.notify({
          color: 'danger',
          title: `Currency not active`,
          text: `The currency ${currencyCode} you selected is no longer ative. It will be disabled on this item.`,
        });
        return 0;
      }
      return currency.conversionRate;
    },
    addAlternateCurrency() {
      this.showAlternateCurencyPopup = true;
    },
    saveAlternateCurrency() {
      this.$validator.validateAll('manage-currencies').then((valid) => {
        if (valid) {
          this.showAlternateCurencyPopup = false;
        } else {
          this.$vs.notify({
            color: 'danger',
            title: 'You have errors in your data',
            text: `Ensure that the prices are correct`,
          });
        }
      });
    },
    initValues() {
      if (this.data._id) return;
      this.id = null;
      this.init = false;
      this.product_code = `${this.makeid(2)}-${Date.now()}`;
      this.product_name = '';
      this.cost_price = 0;
      this.low_stock = 0;
      this.retail_price = 0;
      this.wholesale_price = 0;
      this.s_av_branch = {};
      this.av_branch = {};
      this.all_branches = false;
      this.prices = {};
      this.selectedCurrencies = [];
    },
    onBarcodeScanned(barcode) {
      if (!barcode.includes('Enter')) {
        return;
      }
      const code = barcode.replace(/\D/g, '');
      if (code.length) {
        this.product_code = code;
      }
    },
    makeid(length) {
      let result = '';
      const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength),
        );
        counter += 1;
      }
      return result;
    },
    onCheckbox(val) {
      // console.log("val",val)
    },
    submitData() {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          this.$vs.notify({
            color: 'warning',
            title: 'Processing',
            text: 'Processing Data',
          });

          for (const key in this.av_branch) {
            if (this.av_branch.hasOwnProperty(key)) {
              const e = this.av_branch[key];
              if (this.av_branch[key] != true) {
                delete this.s_av_branch[key];
              }
            }
          }

          for (const currencyCode in this.prices) {
            if (Object.hasOwnProperty.call(this.prices, currencyCode)) {
              this.prices[currencyCode].cost_price = parseFloat(
                this.prices[currencyCode].cost_price,
              );
              this.prices[currencyCode].retail_price = parseFloat(
                this.prices[currencyCode].retail_price,
              );
              this.prices[currencyCode].wholesale_price = parseFloat(
                this.prices[currencyCode].wholesale_price,
              );
            }
          }

          const obj = {
            _id: this.id,
            _rev: this.rev,
            product_code: this.product_code,
            product_name: this.product_name.toUpperCase(),
            cost_price: parseFloat(this.cost_price),
            low_stock: this.low_stock,
            retail_price: parseFloat(this.retail_price),
            wholesale_price: parseFloat(this.wholesale_price),
            // stock: this.s_av_branch,
            prices: this.prices,
            currencies: this.selectedCurrencies,
            version: 2,
          };
          console.log('obj', obj, this.prices);

          if (this.id !== null && this.rev !== null) {
            // Check if product code was updated
            if (
              this.original_product_code &&
              obj.product_code != this.original_product_code
            ) {
              const isUnique = await this.$pouch
                .find(
                  {
                    selector: {
                      product_code: obj.product_code,
                    },
                  },
                  'express',
                )
                .then((r) => {
                  return r.docs.length > 1 || r.docs.length == 0;
                })
                .catch((e) => {
                  return true;
                });

              if (!isUnique) {
                this.$vs.notify({
                  color: 'danger',
                  title: 'Duplicate Item Code',
                  text: `Item code '${obj.product_code}' already exists`,
                });
                return;
              }
            }

            // delete obj.stock;
            this.$store
              .dispatch('products/updateProduct', obj)
              .then(() => {
                this.$vs.notify({
                  color: 'success',
                  title: 'Item Updated Successfully',
                  text: 'You have successfully updated an item',
                });
                // Don't run stock update since the stock here
                this.$emit('closeSidebar');
                this.initValues();
              })
              .catch((err) => {
                console.error(err);
                this.$vs.notify({
                  color: 'danger',
                  title: 'Error',
                  text: err,
                });
              });
          } else {
            delete obj._id;
            delete obj._rev;

            // Check if product code exists
            const isUnique = await this.$pouch
              .find(
                {
                  selector: {
                    product_code: obj.product_code,
                  },
                },
                'express',
              )
              .then((r) => {
                return r.docs.length == 0;
              })
              .catch((e) => {
                return true;
              });

            if (!isUnique) {
              this.$vs.notify({
                color: 'danger',
                title: 'Duplicate Item Code',
                text: `Item code '${obj.product_code}' already exists`,
              });
              return;
            }

            this.$store
              .dispatch('products/addProduct', obj)
              .then((insertedRes) => {
                this.$vs.notify({
                  color: 'success',
                  title: 'Item Added Successfully',
                  text: 'You have successfully added a new item',
                });

                // console.log(insertedRes);
                for (const key in this.s_av_branch) {
                  this.$store
                    .dispatch('products/addProductStockUpdate', {
                      product_id: insertedRes.id,
                      branch_id: key,
                      quantity: this.s_av_branch[key],
                      action: 'add',
                      valid: true,
                      type: 'stock_update',
                      caller: 'add_product',
                    })
                    .catch((err) => {
                      console.error(err);
                      this.$vs.notify({
                        color: 'danger',
                        title: 'Error',
                        text: err,
                      });
                    });

                  // v2 stocks
                  this.$store
                    .dispatch('products/addProductStock', {
                      item_id: insertedRes.id,
                      branch_id: key,
                      stock: Number(this.s_av_branch[key]),
                      // TODO: Add pricing here, for branch specific procong strategy
                    })
                    .catch((err) => {
                      console.error(err);
                      this.$vs.notify({
                        color: 'danger',
                        title: 'Error',
                        text: err,
                      });
                    });
                }

                this.$emit('closeSidebar');
                this.initValues();
              })
              .catch((err) => {
                console.error(err);
                this.$vs.notify({
                  color: 'danger',
                  title: 'Error',
                  text: err,
                });
              });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
